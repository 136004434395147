/* jshint esversion: 6, multistr: true */

import './contact.css';


function styleHeader() {
    $('#hero-container').addClass('split-city-header');
    $('#hero-container').append('\
    <div class="container">\
        <div class="row">\
            <div class="title">\
                <h1>Επικοινωνία</h1>\
                <p>Η έμπειρη ομάδα του 4ty.gr είναι πάντα στην διάθεσή σας έτοιμη να σας εξυπηρετήσει με προθυμία.</p>\
            </div>\
        </div>\
    </div></div>\
    ');
}


function styleOffices() {
    $('.main-page-table').after('\
        <div class="row offices">\
            <h2>Γραφεία 4ty.gr</h2>\
            <div class="col-md-4 office">\
                <h4>ΜΟΣΧΑΤΟΥ</h4>\
                <div class="contact-details">\
                    <div class="address">Κοραή 19, 183 45 <br /> Μοσχάτο / Αττική</div>\
                    <div class="phone">211 4113483</div>\
                </div>\
                <a class="map moschato" href="https://www.google.com/maps/place/Korai+19,+Moschato+183+45/@37.9537328,23.6782296,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1bc6ff8a32517:0xa276b221876709d6!8m2!3d37.9537328!4d23.6804183"></a>\
            </div>\
            <div class="col-md-4 office">\
                <h4>ΒΟΛΟΥ</h4>\
                <div class="contact-details">\
                    <div class="address">Ελ. Βενιζέλου 43, <br /> 383 33  Βόλος</div>\
                    <div class="phone">24210 30511</div>\
                </div>\
                <a class="map el-venizelou" href="https://www.google.com/maps/place/El.+Venizelou+43,+Volos+383+33/@39.3642638,22.9460773,17z/data=!3m1!4b1!4m5!3m4!1s0x14a76c6148bdb097:0xb4250dc70f3b6f3c!8m2!3d39.3642638!4d22.948266"></a>\
            </div>\
            <div class="col-md-4 office">\
                <h4>ΛΑΡΙΣΑΣ</h4>\
                <div class="contact-details">\
                    <div class="address">Μεγάλου Αλεξάνδρου 3 <br /> 412 22 Λάρισα</div>\
                    <div class="phone">2411 810265</div>\
                </div>\
                <a class="map meg-aleksandrou" href="https://www.google.com/maps/place/Meg.+Alexandrou+3,+Larisa+412+22/@39.6385118,22.4146933,17z/data=!3m1!4b1!4m5!3m4!1s0x1358889759673ffd:0x120eea2200def313!8m2!3d39.6385118!4d22.416882"></a>\
            </div>\
        </div>\
    ');
}


export default function styleContact() {
    if ($('body').hasClass('directory-merchand-page') && $('#contact-form').length > 0) {
        styleHeader();
        styleOffices();
        $('.social-media-contact ul .contact-google').parent('li').remove();
        $('.social-media-contact ul').append('<li><a class="contact-insta" href="https://www.instagram.com/epaggelmatikosodigos_4ty.gr/"><span>Instagram</span></a></li>');
        $('.social-media-contact ul .contact-facebook').attr('href', 'https://www.facebook.com/4tygr');
        $('body').addClass('contact');
    }
}