/* jshint esversion: 6, multistr: true */

import './onePage.css';
import getContactDetails from '../metaContactDetails.js';
import createMap from '../googleMaps/loadMap.js';
import initMap from '../maps/maps.js';


var metaContactDetails = getContactDetails();


function initMainMenu() {
    $('#website-menu ul > li:nth-of-type(1)').addClass('home');
}


function styleGallery() {
    // Show the first 4 pics.
    if (window.outerWidth >= 768) {
        $('#photo-gallery > li').slice(0, 4).addClass('shown');
    } else {
        $('#photo-gallery > li').slice(0, 1).addClass('shown');
    }
    window.onresize = function() {
        if (window.outerWidth >= 768) {
            $('#photo-gallery > li').slice(0, 4).addClass('shown');
        } else {
            $('#photo-gallery > li.shown').slice(1, 4).removeClass('shown');
        } 
    }
    
    // Add controls
    if ($('#photo-gallery > li').length > 4) {
        $('#photo-gallery').append('<span class="gallery-control prev disabled"></span><span class="gallery-control next"></span>');
        // Iterate gallery pics
        $('#photo-gallery').on('click', '.gallery-control', function() {

            var nextPic = $('#photo-gallery .shown').last().next('li');
            var prevPic = $('#photo-gallery .shown').first().prev('li');

            if (! $(this).hasClass('disabled')) {
                // Next
                if ($(this).hasClass('next')) {
                    if (nextPic.length > 0 ) {
                        $('#photo-gallery .shown').first().removeClass('shown');
                        nextPic.addClass('shown');
                    }
                }
                // Prev
                if ($(this).hasClass('prev')) {
                    if (prevPic.length > 0 ) {
                        $('#photo-gallery .shown').last().removeClass('shown');
                        prevPic.addClass('shown');
                    }
                }
                // Toggle controls
                nextPic = $('#photo-gallery .shown').last().next('li');
                prevPic = $('#photo-gallery .shown').first().prev('li');
                if (nextPic.length <= 0) {
                    $('#photo-gallery .next').addClass('disabled');
                } else {
                    $('#photo-gallery .next.disabled').removeClass('disabled');
                }
                if (prevPic.length <= 0) {
                    $('#photo-gallery .prev').addClass('disabled');
                } else {
                    $('#photo-gallery .prev.disabled').removeClass('disabled');
                }
            }
        });
    }
    // Initialize lightbox
    $('#photo-gallery li > a').lightBox();
}


function initMore() {
    $('.window.results > .content > a').insertAfter('#map-wrapper').wrapAll('<div class="area-category container"></div>');
}


function handleScrolling() {
    var curPos = 0;
    $(window).on('scroll', function(e) {
        curPos = document.documentElement.scrollTop;
        // Menu
        if (curPos > 400) {
            $('#website-menu').addClass('fixed');
        } else {
            $('#website-menu').removeClass('fixed');
        }
    });

    // Scrolling via main menu
    var headerHeight = 130;
    // Catch the clicks in the main menu, figure out where the menu item should navigate to by checking its
    // href attribute and scroll the page to that element's position.
    $('#website-menu').on('click', 'a', function(e) {
        e.preventDefault();
        console.log($(this).attr('href'));
        if ($(this).attr('href') == '#profile') {
            $('html, body').animate({scrollTop: $('#profile').offset().top - headerHeight}, 800);
        
        } else if ($(this).attr('href') == '#services') {
            $('html, body').animate({scrollTop: $('#services').offset().top - headerHeight}, 800);

        } else if ($(this).attr('href') == '#gallery') {
            $('html, body').animate({scrollTop: $('#gallery').offset().top - headerHeight}, 800);

        } else if ($(this).attr('href') == '#contact') {
            $('html, body').animate({scrollTop: $('#contact').offset().top - headerHeight}, 800);

        } else if ($(this).attr('href') == '#home') {
            $('html, body').animate({scrollTop: 0}, 800);

        }
    });
}


function toggleWorkingDays() {
    // Hides the work schedule if none of the days are filled.
    if ($('#working-hours').length > 0) {
        let emptyCount = 0;
        $('#working-hours .days').each(function() {
            if ($(this).find('.col-md-6:nth-of-type(2)').text().trim().length < 1) emptyCount ++;
        });

        if (emptyCount >= 7) $('#working-hours').hide();
    } 
}


function topContactInfo() {
    // Remove empty contact-info elements.
    $('#top-contact-info span').each(function() {
        if ($(this).text().length === 0) {
            $(this).remove();
        }
    });
}


export default function styleOnePage() {
    if ($('body').hasClass('merchant-one-page')) {
        console.log('==> Styling one page...');
        initMainMenu();

        // Remove the indicators if there is only one slide.
        if ($('#myCarousel .item').length <= 1) $('#myCarousel .carousel-indicators').hide();

        $('#services > *').wrapAll('<div class="container"><div class="row"></div></div>');
        styleGallery();
        initMap($('#map-wrapper'), [lat, lng]);
        initMore();
        handleScrolling();
        toggleWorkingDays();
        topContactInfo();
    }
}