/* jshint esversion: 6, multistr: true */
/*
  Creates a google map from the pages contact details and appends it to the
  body.
*/
import getContactDetails from '../metaContactDetails.js';


export default function createMap(domParent) {
  let metaContactDetails = getContactDetails();
  let clAddress = metaContactDetails.address.replace('&', ' ');
  let mapOptions = {
    "APIkey": "AIzaSyBQTPTxan4kS3PNmaHXqwBQ0XvDDMhfdsA",
    "mode": "place",
    "place": clAddress.replace(' ', '+') + '+' + metaContactDetails.city,
    "coords": metaContactDetails.latitude + "," + metaContactDetails.longitude,
    "zoom": "14",
    "maptype": "roadmap",
    "language": lang
  };

  let mapEl = '\
    <iframe id="googlemaps"\
      width="100%" height="100%" frameborder="0" style="border:0"\
      src="https://www.google.com/maps/embed/v1/place?key='+mapOptions.APIkey+'&q='+mapOptions.place+'&zoom='+mapOptions.zoom+'" allowfullscreen>\
  ';
  domParent.html(mapEl);
}
