/* jshint esversion: 6, multistr: true */
/*
Styles the homePage.
*/
import './styles.css';


function applyStyles() {
  const mainBody = $('main');
  mainBody.find('#home-proposals, #home-offer').unwrap();
  mainBody.find('#home-proposals').children().wrapAll('<div class="container"></div>');
  mainBody.find('#home-offer').children().wrapAll('<div class="container"></div>');
}


function createViewAllExpoItem(parent) {
  // Add a view all expo item at the end.
  const viewAllBtn = parent.find('.viewall');
  parent.find('.expo-list .expo-item:last-of-type').after('<li class="col-md-3 home-list-proposals-item expo-item view-all"><a href="'+viewAllBtn.attr('href')+'"><span>'+viewAllBtn.text()+'</span></a></li>');
}


function handleProposals() {
  const parent = $('#home-proposals');
  parent.addClass('home-sector');
  parent.find('.home-list-proposals').addClass('expo-list');
  parent.find('.home-list-proposals > li').addClass('expo-item');

  parent.find('.expo-list .expo-item').slice(0, 4).addClass('shown');
  parent.find('.next, .prev').appendTo(parent.find('.expo-list'));

  parent.find('.expo-list .expo-item').each(function() {
    // Cut down the titles
    let title = $(this).find('.title');
    title.text( $.trim( title.text() ).substring(0, 70).split(" ").slice(0, -1).join(" ") + "..." );
    // split the address and phone
    let splitted = $(this).find('.area').text().split("\n");
    $(this).find('.area').after('<div class="address">'+splitted[1]+'</div><div class="phone">'+splitted[2]+'</div>');
    $(this).find('a:first-of-type').attr('target', '_blank');
    // Add the more button
    let link = $(this).find('a:first-of-type').attr('href');
    $(this).find('.phone').after('<a class="more" href="'+link+'" target="_blank">Περισσότερα</a>');
  });
  createViewAllExpoItem(parent);
}


function handleHomeOffers() {
  const parent = $('#home-offer');
  parent.addClass('home-sector');
  parent.find('.list-all-offers-home').addClass('expo-list');
  parent.find('.list-all-offers-home > li').addClass('expo-item');
  parent.find('.home-offers-next').addClass('next').appendTo(parent.find('.expo-list'));
  parent.find('.home-offers-prev').addClass('prev').appendTo(parent.find('.expo-list'));

  parent.find('.expo-item').slice(0, 4).addClass('shown');
  parent.find('.expo-list > .expo-item').each(function() {
    $(this).find('.title').prependTo($(this).find('.description'));
  });
  createViewAllExpoItem(parent);
}


function handleAggelies() {
  const parent = $('body.c2018.home #aggelies2');
  parent.addClass('home-sector');
  parent.children('.col-md-9').attr('class', 'container');
  parent.children('.container').prepend('<h2 id="aggelies2-title">'+parent.find('.titleallpostspaging>.title').text()+'</h2>');
  parent.find('.resultsitems').addClass('expo-list');
  parent.find('.resultscontainer .resultsitems > .result').addClass('expo-item');
  parent.find('.next, .prev').appendTo(parent.find('.expo-list'));

  parent.find('.resultscontainer .resultsitems > .result').each(function() {
    $(this).find('a.wrap > span:not(:first-of-type)').wrapAll('<div class="contents"></div>');
    // The website url should be http. Not https
    let url = $(this).children('a.wrap').attr('href');
    if (url.indexOf('https://www.4ty.gr') <= 0) {
      url = url.replace('https://', 'http://');
      $(this).children('a.wrap').attr('href', url);
    }
  });

  parent.find('.expo-list').append('<span class="next"></span>');
  parent.find('.resultsitems > .result').slice(0, 4).addClass('shown');
}


function handleNewProducts() {
  const parent = $('body.c2018.home .home-new-products');
  parent.addClass('home-sector');
  parent.children().wrapAll('<div class="container"></div>');
  // parent.find('h2.home-new-products-title').after('<a href="/4tyshops" class="viewall">ΠΡΟΒΟΛΗ ΟΛΩΝ</a>');

  parent.find('.home-new-products-list').addClass('expo-list');
  parent.find('.expo-list > li').addClass('expo-item');
  parent.find('.expo-list').append('<a href="#" class="prev">prev</a><a href="#" class="next">next</a>');

  parent.find('.expo-list > .expo-item').slice(0, 4).addClass('shown');

  parent.find('.expo-list > .expo-item').each(function() {
    $(this).find('.product-name').insertBefore($(this).find('.product-info'));
  });
  createViewAllExpoItem(parent);
}


function handleSectorArrows() {
  $('body.c2018 .expo-list .next').addClass('active');
  // next
  $('body.c2018 .expo-list').on('click', '.next', function(e) {
    e.preventDefault();
    let parent = $(e.target).closest('.expo-list');
    if (parent.find('.expo-item.shown').length > 1) {
      if (parent.find('.expo-item.shown').last().next('.expo-item').length > 0) {
        parent.find('.expo-item.shown').first().removeClass('shown');
        parent.find('.expo-item.shown').last().next().addClass('shown');
        parent.find('.prev').addClass('active');
        if (parent.find('.expo-item.shown').last().next('.expo-item').length < 1) {
          parent.find('.next.active').removeClass('active');
        }
      }
    } else {
      if (parent.find('.expo-item.shown').next('.expo-item').length > 0) {
        parent.find('.expo-item.shown').next().addClass('shown');
        parent.find('.expo-item.shown').first().removeClass('shown');
        parent.find('.prev').addClass('active');
        if (parent.find('.expo-item.shown').last().next('.expo-item').length < 1) {
          parent.find('.next.active').removeClass('active');
        }
      } else {
        parent.find('.next.active').removeClass('active');
      }
    }

  });
  // prev
  $('body.c2018 .expo-list').on('click', '.prev', function(e) {
    e.preventDefault();
    let parent = $(e.target).closest('.expo-list');
    if (parent.find('.expo-item.shown').length > 1) {
      if (parent.find('.expo-item.shown').first().prev('.expo-item').length > 0) {
        parent.find('.expo-item.shown').last().removeClass('shown');
        parent.find('.expo-item.shown').first().prev().addClass('shown');
        parent.find('.next').addClass('active');
        if (parent.find('.expo-item.shown').first().prev('.expo-item').length < 1) {
          parent.find('.prev.active').removeClass('active');
        }
      }
    } else {
      if (parent.find('.expo-item.shown').prev('.expo-item').length > 0) {
        parent.find('.expo-item.shown').prev().addClass('shown');
        parent.find('.expo-item.shown').last().removeClass('shown');
        parent.find('.next').addClass('active');
        if (parent.find('.expo-item.shown').first().prev('.expo-item').length < 1) {
          parent.find('.prev.active').removeClass('active');
        }
      }
    }
  });
}


function handleCategoriesAndSearches() {
  $('body.c2018 .home-new-products').closest('.row').after('<div class="row home-sector" id="populars"></div>');
  const parent = $('body.c2018 #populars');
  let popCategories = $('#popular-categories');
  let popSearches = $('#popular-searches');

  // Create the tabs we need
  parent.append('\
    <div class="nav-row row"><div class="container">\
      <ul class="nav nav-pills" role="tablist">\
        <li role="presentation" class="active"><a href="#pop-categories" aria-controls="pop-categories" role="tab" data-toggle="tab">'+popCategories.find('h3.popular-title').text()+'</a></li>\
        <li role="presentation"><a href="#pop-searches" aria-controls="pop-searches" role="tab" data-toggle="tab">'+popSearches.find('h3.popular-title').text()+'</a></li>\
      </ul>\
    </div></div>\
    <div class="tab-content-row row"><div class="container">\
      <div class="tab-content">\
        <div role="tabpanel" class="tab-pane active" id="pop-categories">'+popCategories.find('.categories-searches-list').html()+'</div>\
        <div role="tabpanel" class="tab-pane" id="pop-searches">'+popSearches.find('.popular-searches-list').html()+'</div>\
      </div></div>\
    </div>\
  ');

  $('#populars .button-show-all').wrap('<div class="show-all-container col-md-12"></div>');
  $('#pop-categories .popular-categories-item').slice(0, 12).addClass('show');
  $('#pop-searches .popular-searches-item').slice(0, 12).addClass('show');

  $('.button-show-all').on('click', function(e) {
    $(e.target).closest('.tab-pane').find('.result').slice(12).toggleClass('show');
    $(e.target).closest('.tab-pane').toggleClass('open');
  });
}


function handleBusinessPromotion() {
  const parent = $('#businesspromotion');
  parent.parent().attr('class', 'container');
  parent.closest('.row').addClass('home-sector businesspromotion');
  parent.find('#businesspromotioncontainer > a');
  const katachorisiUrl = parent.find('#businesspromotioncontainer > a:first-of-type').attr('href'),
        istoselidaUrl = parent.find('#businesspromotioncontainer > a:nth-child(2)').attr('href'),
        eshopUrl = parent.find('#businesspromotioncontainer > a:nth-child(3)').attr('href'),
        bannerUrl = parent.find('#businesspromotioncontainer > a:last-of-type').attr('href');
  parent.find('h3').after('\
  <div class="row" id="promotion-container">\
    <div class="col-md-3 promo katachorisi"><div class="inner"><a href="'+katachorisiUrl+'" class="overlay"><h4>Καταχώρηση</h4></a></div></div>\
    <div class="col-md-3 promo istoselida"><div class="inner"><a href="'+istoselidaUrl+'" class="overlay"><h4>Ιστοσελίδα</h4></a></div></div>\
    <div class="col-md-3 promo eshop"><div class="inner"><a href="'+eshopUrl+'" class="overlay"><h4>E-shop</h4></div></a></div>\
    <div class="col-md-3 promo banner"><div class="inner"><a href="'+bannerUrl+'" class="overlay"><h4>Banner</h4></div></a></div>\
  </div>\
  ');
}


function styleBanners() {
  let banner1 = $('#animations-bottom');
  let banners = $('.anim-col-wrap');

  if (banner1.length > 0) {
    banner1.insertAfter('#home-proposals .expo-list');
  }
  if(banners.length > 0) {
    $('#home-offer').after('<div id="banners" class="home-sector"><div class="container"><div class="row"></div></div></div>');
    banners.appendTo('#banners > .container > .row');
  }
}


function mobileHome() {

}


function initFBSDK() {
  window.fbAsyncInit = function() {
    FB.init({
      appId            : 'your-app-id',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v4.0'
    });
  };
  $.getScript( "https://connect.facebook.net/en_US/sdk.js" )
  .done(function( script, textStatus ) {
    console.log('FB SDK loaded successfully.');
  })
  .fail(function( jqxhr, settings, exception ) {
    console.log(exception);
  });
}


var initFBMessaging = function(fbPage) {
  // Get the fb SDK
  $('body').prepend('<div id="fb-root" class="closed"></div> \
      <script>(function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; \
      if (d.getElementById(id)) return; \
      js = d.createElement(s); js.id = id; \
      js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6"; \
      fjs.parentNode.insertBefore(js, fjs); \
      }(document, "script", "facebook-jssdk"));</script>');

  // Create the fb messaging container
  $('body').append('<div id="fb-messaging"> \
              <div class="title"><span class="icon"></span></div> \
              <div class="fb-page" data-href="'+ fbPage +'" data-tabs="messages" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><div class="fb-xfbml-parse-ignore"><blockquote cite="'+ fbPage +'"><a href="'+ fbPage +'">Facebook page</a></blockquote></div></div></div>');

  // Toggle messaging open/close
  $('#fb-messaging>.title').on('click', function() {
    $('#fb-messaging').toggleClass('open');
  });
};


export default function styleHomePage() {
  if ($('body').hasClass('home')) {
    applyStyles();
    handleProposals();
    handleHomeOffers();
    handleAggelies();
    handleNewProducts();
    handleCategoriesAndSearches();
    handleBusinessPromotion();
    styleBanners();

    handleSectorArrows();

    initFBMessaging('https://www.facebook.com/4tygr');

    if ($(window).width() < 670) {
      $('.expo-list').each(function() {
        $(this).find('.expo-item').slice(1).removeClass('shown');
      });
    }
    
  }
}
