/* jshint esversion: 6, multistr: true */
import './proposals.css';


function styleProposalsPage() {
  // $('h1').empty();
  // $('#search-text').appendTo('h1');
  $('#hero-container').append('<div class="container"><div class="row"></div></div>');
  $('h1').appendTo('#hero-container .row').wrap('<div class="title"></div>');
  $('header .title').append('<p>Οι καλύτερες προτάσεις για διμαμονή, υγεία αλλά και για οποιαδήποτε άλλη κατηγορία επαγγέλματος αναζητάτε.</p>');
}


function styleProposalEntities() {
  let propCount = 0;
  let catCount = 0;
  let category = '';
  let categories = [];
  $('#intro').after('<ul class="categories nav nav-pills"><li><a href="#" class="btn active">ΟΛΑ</a></li></ul>');
  $('#intro').remove();

  $('#proposals .proposal').each(function() {
    propCount += 1;
    let slideshowId = 'propSlideshow-' + propCount;
    let proposal = $(this);

    // create the slideshow
    let imagesContainer = proposal.find('span.white-border:first-of-type');
    imagesContainer.before('\
      <div id="'+slideshowId+'" class="carousel slide" data-ride="carousel"> \
        <div class="carousel-inner" role="listbox"> \
        </div> \
        <a class="left carousel-control" href="#'+slideshowId+'" role="button" data-slide="prev"> \
          <span class="icon" aria-hidden="true"></span> \
        </a> \
        <a class="right carousel-control" href="#'+slideshowId+'" role="button" data-slide="next"> \
          <span class="icon" aria-hidden="true"></span> \
        </a> \
      </div>');
    imagesContainer.find('img').attr('class', '').appendTo(proposal.find('.carousel-inner')).wrap('<div class="item"></div>');
    proposal.find('.carousel-inner > .item:first-of-type').addClass('active');

    // Define categories
    category = proposal.find('.category').text().trim();
    if (categories.includes(category) === false) {
      catCount += 1;
      categories.push(category);
      $('ul.categories').append('<li><a href="#" data-category="cat'+catCount+'" class="btn">'+category+'</a></li>');
    }
    proposal.attr('data-category', 'cat' + catCount);
    $('.carousel.slide').carousel({
      interval: false
    });
  });

  // Filter by category
  let currentCategory;
  $('.categories > li > .btn').on('click', function(e) {
    e.preventDefault();
    $('.categories > li > .btn').removeClass('active');
    $(e.target).addClass('active');
    currentCategory = $(e.target).data('category');
    console.log(currentCategory);
    if (currentCategory) {
      $('#proposals .proposal:not([data-category="'+currentCategory+'"])').hide();
      $('#proposals .proposal[data-category="'+currentCategory+'"]').show();
    } else {
      $('#proposals .proposal').show();
    }
  });

  console.log(categories);
}


function styleOffersPage() {
  $('#hero-container').append('<div class="container"><div class="row"></div></div>');
  $('h1').appendTo('#hero-container .row').wrap('<div class="title"></div>');
  $('header .title').append('<p>Εξερευνίστε τις μοναδικές προσφορές από τα μέλη του 4ty και επωφεληθείτε.</p>');
}
function styleOffersEntities() {
  $('.c2018.offers ul.paging').addClass('row');
  $('.c2018.offers ul.paging').append('<div class="col-md-3 col-1"></div> <div class="col-md-3 col-2"></div> <div class="col-md-3 col-3"></div> <div class="col-md-3 col-4"></div>');
  $('.coupon-learn-more').text('Προλάβετε');

  let currentCol = 1;
  $('.c2018.offers ul.paging li').each(function() {
    $(this).appendTo($('ul.paging > .col-'+currentCol));
    if (currentCol < 4) {
      currentCol += 1;
    } else {
      currentCol = 1;
    }
  });
}


export default function styleProposals() {
  if ($('#proposals').length > 0) {
    $('body').addClass('proposals');
    styleProposalsPage();
    styleProposalEntities();
  } else if ($('.coupons .coupon').length > 0) {
    $('body').addClass('offers');
    styleOffersPage();
    styleOffersEntities();
  }
}
