/* jshint esversion: 6, multistr: true */

import './faq.css';


function styleHeader() {
    $('#hero-container').addClass('split-city-header');
    $('#hero-container').append('\
    <div class="container">\
        <div class="row">\
            <div class="title">\
                <h1>FAQ</h1>\
                <p>Μάθετε τα πάντα για τον 4ty.gr αλλά και για όλες τις υπηρεσίες που προσφέρει.</p>\
            </div>\
        </div>\
    </div></div>\
    ');
}


function styleQs() {
    let parent = $('#tabs');
    // $("#tabs > .ui-tabs-panel ul > li").each(function() {
    //     $(this)[0].onclick = null;
    // });

    $('#tabs').on('click', '.question', function(e) {
        // e.preventDefault();
        console.log('==> Question clicked.');
        let q = $(e.target);
        console.log(q);
        console.log(parent.find('.answer'));
        
        parent.find('.answer').slideUp('fast');
        if (q.length > 0) {
            console.log('==> Opening answer');
            if (q.next('.answer').length <= 0) {
                q.after('<div class="answer">'+q.data('answer')+'</div>');
            } else {
                q.next('.answer').slideDown('fast');
            }
            
        }
    });
}


export default function styleFaq() {
    if ($('#faq').length > 0) {
        styleHeader();
        styleQs();
        $('body').addClass('faq');
    }
}