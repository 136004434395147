/* jshint esversion: 6 */
import './styles.css';
import initAds from './googleAds/googleAds.js';
import styleHeader from './header/header.js';
import createMap from './googleMaps/loadMap.js';
import style4tyShops from './4tyShops/4tyShops.js';
import styleHomePage from './homePage/homePage.js';
import styleFooter from './footer/footer.js';
import styleDirectory from './directory/directory.js';
import styleProposals from './proposals/proposals.js';
import styleCategoriesPage from './categories/categories.js';
import styleAreas from './areas/areas.js';
import styleContact from './contact/contact.js';
import styleFaq from './faq/faq.js';
import styleOnePage from './onePage/onePage.js';


function loadRoboto() {
  let link = document.createElement('link');
  link.id = 'roboto-font';
  link.rel = 'stylesheet';
  link.href = 'https://fonts.googleapis.com/css?family=Roboto:300,400,700&amp;subset=greek';
  document.head.appendChild(link);
}


// Sets a class to the body to show where we are.
function setPageClass() {
  if ($('.home-slideshow').length > 0) {
    $('body').addClass('home');
  }
  if ($('#selected-category-area').length > 0) {
    $('body').addClass('directory');
  }
}


function styleGeneralPage() {
  if ($('.container.text-service').length > 0) {
    $('body').addClass('general-page');
    $('#hero-container').attr('class', 'row split-city-header').append('\
      <div class="container">\
          <div class="row">\
              <div class="title">\
                  <h1>'+$('#texttop h1').text()+'</h1>\
              </div>\
          </div>\
      </div></div>\
    ');
  }
}


function goToTop() {
  $('body').append('<div id="go-top"></div>'); 
  $('#go-top').on('click', function(e) {    
    $("html, body").animate({ scrollTop: "0px" });
  });
}


function loadGoogleAds() {
  $.getScript( "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" )
  .done(function( script, textStatus ) {
    (adsbygoogle = window.adsbygoogle || []).push({
      google_ad_client: "ca-pub-7549788657822029",
      enable_page_level_ads: true
    });
    console.log('Google ads loaded.');
  })
  .fail(function( jqxhr, settings, exception ) {
    console.log('Error loading Google.');
  });
}


function addExtraMetas() {
  var metaTag = document.createElement('meta');
  metaTag.setAttribute('name', 'google-site-verification');
  metaTag.content = 'vTpLZV9rVtnyDonFJUBUVpMBmUUEBNZDbHTcUMV-ctU';
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}


$(document).ready(function() {
  if ($('body').hasClass('c2018')) {

    styleHeader();
    styleFooter();

    styleHomePage();
    styleDirectory();
    styleProposals();
    style4tyShops();

    styleCategoriesPage();
    styleAreas();

    styleContact();

    styleOnePage();

    styleGeneralPage();
    
    styleFaq();

    // loadGoogleAds();
    initAds();

    goToTop();

  } else {
    // Load the map if we are in a merchant's directory page
    if ($('#map').length > 0 && $('#merchant-logo').length > 0 && $('.merchant-header').length > 0) {
      $('#map').empty();
      createMap($('#map'));
    }
  }


});
