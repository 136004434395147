/* jshint esversion: 6, multistr: true */
import './categories.css';
import initMap from '../maps/maps.js';


function categoriesSearch() {
    $('#hero-container').after('<div id="catSearch"><div class="container"><input class="search" type="text" placeholder="Αναζήτηση κατηγορίας"/></div></div>');
    $('')

    // Filter the categories
    let searchInp = $('#catSearch input.search');
    let searchText = searchInp.val();
    let li = $('#mapResults li');
    let catTextEl;
    let catText = '';
    let searchResults = [];

    searchInp.on('input', function() {
        searchText = searchInp.val().toUpperCase();
        if (searchText.length > 2) {
            console.log(searchText);
            // Iterate over the categories list and hide the ones that do not much our search terms.
            for (let i = 0; i < li.length; i++) {
                catTextEl = li[i].getElementsByTagName('span')[0];
                if (! catTextEl) {
                    catTextEl = li[i].getElementsByTagName('a')[0];
                }
                if (catTextEl) {
                    catText = catTextEl.textContent || catTextEl.innerText;

                    if (catText.toUpperCase().indexOf(searchText) < 0) {
                        $(li[i]).addClass('hidden');
                    } else {
                        $(li[i]).removeClass('hidden');
                        if ($(li[i]).closest('li.has-submenu')) {
                            $(li[i]).closest('li.has-submenu').removeClass('closed hidden');
                            if (! $(li[i]).closest('ul').is(':visible')) {
                                $(li[i]).closest('ul').slideDown('fast');
                            }
                        }
                    }
                }
            }
        } else {
            $('#mapResults li.hidden').removeClass('hidden');
        }
    
    });

}


function styleCategories() {
    $('#mapHolder').appendTo('#hero-container');
    $('#mapResults').addClass('row').appendTo('#page').wrap('<div class="container"></div>');
    $('#mapResults').append('<div class="col-md-4 col-1"></div><div class="col-md-4 col-2"></div><div class="col-md-4 col-3"></div>');
    
    // Move all the categories to the main page (pinterest style)
    let colCount = 1;
    let catCount = 1;
    $('#mapResults > ul > li').each(function() {
        $(this).addClass('category').appendTo('#mapResults > .col-' + colCount);
        $(this).addClass('closed');
        // Pass appending to col-3 for a few loops so that we make some space to place the banner there.
        if (catCount <= 12 && colCount === 2) {
            colCount = 1
        } else if (colCount === 3) {
            colCount = 1;
        } else {
            colCount += 1;
        }
        catCount += 1;
    });

    // Toggle the visibillity of subcategories.
    // ** The functionallity of showing the markers on the map is in the "maps" script.
    $('#mapResults li.category').on('click', function(e) {

        // Check if this has a submenu and we are not in a submenu so that we know toggle it.
        if ($(this).hasClass('has-submenu') && $(e.target).closest('ul').length <= 0) {
            $(this).toggleClass('closed');
            $(this).find('ul').slideToggle('fast');
        } else {
            let merchCat = $(e.target);
            if (merchCat.is('li') === false) merchCat = $(e.target).closest('li');
            merchCat.toggleClass('map');
        }
    });

    // Move the banner in the categories list.
    $('#animations-map').prependTo('#mapResults > .col-3');
}


function stickyMap() {
    console.log('StickyMap');
    if (window.outerWidth > 460) {
        $(window).scroll(function(e) {
            if ($(window).scrollTop() > 310) {
                $('#hero-container').addClass('sticky');
            } else {
                $('#hero-container').removeClass('sticky');
            }
        });
    }
}


export default function styleCategoriesPage() {
    if ($('body').hasClass('business-categories')) {
        $('body').removeClass('other-map-body');
        $('#hero-container').removeClass('split-city-header');
        styleCategories();
        categoriesSearch();
        stickyMap();
        initMap();
    }
    
}