/* jshint esversion: 6, multistr: true */
/*
Styles the katachorisi page.
*/

import './directory.css';
import getContactDetails from '../metaContactDetails.js';
import createMap from '../googleMaps/loadMap.js';
import initMap from '../maps/maps.js';

var contactDetails = getContactDetails();


function styleMain() {
  const parentEl = $('#main-page');
  parentEl.children('.col-md-12').attr('class', 'row');
  parentEl.children('.row').children().attr('class', 'container');
}


function styleContent() {
  let lis;
  if ($('#mainview .entries > li').length > 0) {
    lis = $('#mainview .entries > li');
  } else {
    lis = $('#estateresults > ul > li');
  }

  let content;
  const maxHeight = 35;
  lis.each(function() {
    if ($(this).find('.description > .content').length > 0) {
      content = $(this).find('.description > .content');
    } else {
      content = $(this).find('.search-description');
    }
    // Make the big descriptions smaller and show a button to reveal them.
    if (content.text().trim().length > 0) {
      content.text(content.text().replace(/\r?\n|\r/g, ''));
      if (content.innerHeight() > maxHeight) {
        content.attr('data-height', content.innerHeight());
        content.css('height', maxHeight);
        content.after('<span class="toggle-content"></span>');
      }
    }
  });

  // Toggle the content open / close
  $('.toggle-content').on('click', function(e) {
    let content = $(this).closest('li').find('.description > .content');
    if (content.length <= 0) {
      content = $(this).closest('li').find('.search-description');
    }
    if ($(this).hasClass('opened')) {
      content.animate({
        'height': maxHeight
      }, 300)
      $(this).removeClass('opened');
    } else {
      content.animate({
        'height': content.data('height')
      }, 300)
      $(this).addClass('opened');
    }
  });
}


function createContactDetails(el) {
  el.append('\
    <div class="contact-detail phone">\
      <span class="icon"></span> <span class="value">' + contactDetails.phone + '</span>\
    </div>\
    <div class="contact-detail fax">\
      <span class="icon"></span> <span class="value">' + contactDetails.fax + '</span>\
    </div>\
    <div class="contact-detail email">\
      <span class="icon"></span> <span class="value">' + contactDetails.email + '</span>\
    </div>\
  ');
}


function styleHeader() {
  $('#main-page #title').closest('.row').attr('id', 'merchant-header');
  $('#merchant-logo').appendTo('#title h1');
  $('header > .header').after('<div class="header-details"><div class="contact-details"></div><div class="header-buttons"></div></div>');
  // Contact details.
  $('#merchant-details .merchant-detail.address').appendTo('#title h1');
  $('#merchant-details > .info2 > .content').appendTo('header .contact-details');
  if ($('.header-details .merchant-detail.fax').length > 0) {
    $('.header-details .merchant-detail.phone').wrap('<div class="merchant-detail-container phone-fax"></div>');
    $('.header-details .merchant-detail.fax').appendTo('.merchant-detail-container.phone-fax');
  }
  if ($('.header-details .merchant-detail.email').length > 0 && $('.header-details .merchant-detail.websites').length > 0) {
    $('.header-details .merchant-detail.email').wrap('<div class="merchant-detail-container websites-email"></div>');
    $('.header-details .merchant-detail.websites').prependTo('.merchant-detail-container.websites-email');
  }
  
  $('.merchant-detail.phone > .phone-value').wrap('<a href="tel:'+$('.merchant-detail.phone > .phone-value').text()+'"></a>');
  // Buttons
  if (contactDetails.email) {
    $('header .header-buttons').append('<a href="mailto:'+contactDetails.email+'" target="_top" class="btn email"><span class="icon"></span> E-mail</a>');
  }
  $('header .fb-like').appendTo('header .header-buttons');
}


function styleDescription() {
  $('#merchant-header').after('<div class="row" id="merchant-main"><div class="container"><div class="row descr"></div></div></div>');
  $('#merchantdescription').attr('class', 'col-md-8').appendTo('#merchant-main > .container > .row.descr');
  $('#animations-map').attr('class', 'col-md-4').appendTo('#merchant-main > .container > .row.descr');
  if ($('#merchantdescription > .content').height() > 250) {
    $('#merchantdescription').append('<div class="show-all-container"><span class="btn button-show-all">Μάθετε περισσότερα</span></div>');
    $('#merchantdescription').on('click', '.button-show-all', function(e) {
      $('#merchantdescription').toggleClass('show-all');
    });
  }
}


function styleGallery() {
  $('#photo-gallery').insertAfter('#merchant-main > .container > .row.descr');
  $('#photo-gallery').wrap('<div class="row gallery"></div>');
}


function styleMap() {
  $('#map').removeClass('container').wrap('<div></div>').closest('.row').addClass('map').appendTo('#merchant-main > .container');
  $('#merchant-main .row.map > div').prepend('<h2>ΧΑΡΤΗΣ</h2>');
  initMap($('#map'), [contactDetails.latitude, contactDetails.longitude]);
}

function styleButtons() {
  $('header .area-category').insertAfter('#merchant-main .row.map').addClass('row');
  $('#merchant-main .row.area-category').prepend('<span>Περισσότερα αποτελέσματα: </span>');
}


function createFooterDropup(entry, entryCount, parentEl) {
  parentEl.append('\
    <div class="dropup entry-menu">\
      <button class="btn dropdown-toggle" type="button" id="entry-menu-'+entryCount+'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\
        <span>...</span>\
      </button>\
      <ul class="dropdown-menu" aria-labelledby="entry-menu-'+entryCount+'">\
      </ul>\
    </div>\
  ')
  // Fill the entry's footer menu
  let entryMenu = entry.find('.entry-menu .dropdown-menu');

  if (entry.find('.search-webpage, .site').length > 0) {
    entry.find('.search-webpage, .site').appendTo(entryMenu).wrap('<li></li>').attr('class', 'website');
  } else {
    entryMenu.append('<li><a href="'+entry.find('a.search-title').attr('href')+'" class="btn show-dir" target="_blank">Μάθετε περισσότερα</a></li>');
  }

  if (entry.find('a.eshop').length > 0) {
    entry.find('a.eshop').text('E-Shop').appendTo(entryMenu).wrap('<li></li>');
  }

  if (entry.find('a.email').length > 0) {
    entry.find('a.email').appendTo(entryMenu).wrap('<li><li>');
  }
  if (entry.find('a.mail').length > 0) {
    entry.find('a.email').appendTo(entryMenu).attr('class', 'email').wrap('<li><li>');
  }
  if (entry.find('.webpage-eshop-offer, .search-images, .numimages, .video, .search-offer').length > 0) {
    entryMenu.append('<li class="actions"></li>');
    entry.find('.webpage-eshop-offer>a, .search-offer').appendTo(entryMenu.find('.actions')).attr('class', 'offers');
    entry.find('.search-images, .numimages').appendTo(entryMenu.find('.actions')).attr('class', 'images');
    entry.find('.video').appendTo(entryMenu.find('.actions'));
  }
  
}


function createFooterDetails(entry) {
  let footerEl = entry.find('.entry-footer');

  if (entry.find('.details').length > 0) {
    entry.find('.details').appendTo(footerEl);
  } else {
    entry.find('.search-location, .search-phone').appendTo(footerEl).wrapAll('<div class="details"></div>');
  }
}


function createPager() {

  let pagerText = '';
  let nextUrl = '#';
  let prevUrl = '#';

  if ($('.directory-catalog-paging').length > 0) {

    pagerText = $('.directory-catalog-paging .paging').text();
    if ($('.directory-catalog-paging .next').length > 0) {
      nextUrl = $('.directory-catalog-paging .next').attr('href');
    }
    if ($('.directory-catalog-paging .prev').length > 0) {
      prevUrl = $('.directory-catalog-paging .prev').attr('href');
    }
  
  } else if ($('#pagingresults').length > 0) {
  
    pagerText = $('#pagingresults').text().trim();
    if ($('#pagingresults > a').length > 1) {
      prevUrl = $('#pagingresults > a:first-of-type').attr('href');
      nextUrl = $('#pagingresults > a:last-of-type').attr('href');
    } else {
      nextUrl = $('#pagingresults > a').attr('href');
    }
  
  } else {
    return false;
  }

  let pager = '\
  <div id="pager">\
    <div class="prev"><a href="' + prevUrl + '"></a></div>\
    <div class="paging-text">'+ pagerText +'</div>\
    <div class="next"><a href="' + nextUrl + '"></a></div>\
  </div>\
  ';

  if ( $('#estateresults').length > 0 ) {
    $('#estateresults').append('<div>' + pager + '</div>');
  } else if ( $('#mainview').length > 0 ) {
    $('.directory-catalog-paging').attr('class', '').html(pager);
  }

  if (prevUrl !== '#') $('#pager > .prev').addClass('active');
  if (nextUrl !== '#') $('#pager > .next').addClass('active');

}


function filterCategories(searchInput, searchList) {
  console.log('==> Init Category search');
  console.log(searchInput);
  console.log(searchInput.val());

  searchInput.on('input', function() {
    if (searchInput.val().length > 2) {
      searchList.each(function() {
        if ($(this).text().toUpperCase().indexOf(searchInput.val().toUpperCase()) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });
    } else {
      searchList.show();
    }
  });
}


function styleDirectoryPage() {
  console.log('==> Styling directory page.');
  $('#hero-container').append('<div class="container"><div class="row"></div></div>');
  $('h1, #selected-category-area').appendTo('#hero-container .row');
  let entryCount = 0;
  $('#mainview .entries .entry').each(function() {
    entryCount += 1;
    let title = $(this).find('h2');
    $(this).find('.image').prependTo($(this).find('.description'));
    $(this).find('h3').insertBefore(title);

    $(this).find('.details > a').attr('class', 'email');
    $(this).find('.actions > a.eshop').text('E-shop').attr('class', 'eshop');

    // Entry Footer
    $(this).find('.photos-video-views').attr('class', 'entry-footer');
    createFooterDetails($(this));
    // Footer menu
    let entryActions = $(this).find('a.eshop, a.site, .coupon, .numimages, .mail');
    if (entryActions.length > 0) {
      createFooterDropup($(this), entryCount, $(this).find('.entry-footer'));
    }
  });
  $('#animations-map').appendTo('#animations-map-advertise');

  // Directory search categories
  if ($('#category-catalog').length > 0) {
    $('#category-catalog h2').append('<input id="catSearch" class="search-input" type="text" placeholder="Κατηγορίες (Αναζήτηση)">');
    filterCategories($('#catSearch'), $('#category-catalog ul > li'));
  }

  // Search area
  if ($('#areafilter').length > 0) {
    $('#area-filter').insertAfter('#category-catalog');
    $('#areafilterform').attr('autocomplete', 'off');
    
    let currentURI = decodeURIComponent(window.location.href).split("/").pop();
    let inArea = false;
    
    $('#areafilter').after('<input id="areaSearch" class="search-input" type="text" autocomplete="off" placeholder="Όλες οι περιοχές (Αναζήτηση)">');
    $('#areaSearch').after('<ul style="display: none;" id="areasList"></ul>');
    
    $('#areafilter > option').each(function() {
      if (currentURI == $(this).val()) {
        $('#areaSearch').val($(this).val());
        inArea = true;
      }
      $('#areasList').append('<li data-value="' + $(this).val() + '">' + $(this).text() + '</li>');
    });

    $('#areaSearch').on('click', function() {
      $('#areasList').toggle('fast');
    });
    $('#areasList > li').on('click', function() {
      if (inArea === true) {
        let currentURL = decodeURIComponent(window.location.href).split("/");
        currentURL[currentURL.length -1] = $(this).data('value');
        window.location.href = currentURL.join('/');
      } else {
        window.location.href = window.location.href + '/' + $(this).data('value');
      }
    });
    filterCategories($('#areaSearch'), $('#areasList > li'));
  }

  createPager();
}


function styleSearchPage() {
  console.log('==> Styling search page.');
  $('h1').empty();
  $('#search-text').appendTo('h1');
  $('#page').addClass('container').wrap('<main></main>');
  $('#estateresults').appendTo('main > .container');
  $('#hero-container').append('<div class="container"><div class="row"></div></div>');
  $('#animations-map-advertise').appendTo('#right');
  $('#animations-map').appendTo('#animations-map-advertise');
  $('h1, #selected-category-area').appendTo('#hero-container .row');
  let entryCount = 0;
  $('#estateresults > ul > li').each(function() {
    entryCount += 1;
    // Footer
    $(this).find('.search-description-container').after('<div class="entry-footer"></div>');
    let entryFooter = $(this).find('.entry-footer');
    createFooterDetails($(this));
    // Footer menu
    let entryActions = $(this).find('.search-webpage, .webpage-eshop-offer, .search-images');
    $(this).find('.search-eshop').attr('class', 'eshop');
    if (entryActions.length > 0) {
      createFooterDropup($(this), entryCount, entryFooter);
    }

    $(this).find('a.search-title').wrap('<h2></h2>');
    $(this).find('.search-category').insertBefore($(this).find('h2'));
  });

  createPager();

  // Copy the values of the header search to the category search form
  $('.category-input-wrapper > #category').on('change', function() {
    $('#categorySearch').val($('.category-input-wrapper > #category').val());
  });
  $('.area-input-wrapper > #area').on('change', function() {
    $('#areaSearch').val($('.area-input-wrapper > #area').val());
  });

}


function handleSidebar() {
  let sidebarContent;

  if ($('#area-filter-category-catalog').length > 0) {
    sidebarContent = $('#sidebar2 > .wrapper');
  } else if ($('#right #mapHolder').length > 0 && $('#right #animations-map-advertise').length > 0) {
    $('#right > div').wrapAll('<div class="right-content"></div>');
    sidebarContent = $('#right > .right-content');
  }
  if (sidebarContent.length > 0 && window.outerWidth > 460) {
    let sidebarTop = sidebarContent.offset().top;
    $(window).scroll(function() {
      if ( $(window).scrollTop() > sidebarTop ) {
        sidebarContent.offset({
          top: $(window).scrollTop() + 80
        });
      } else {
        sidebarContent.offset({
          top: sidebarTop
        });
      }
    });
  } 
}


function styleMercPage() {
  styleMain();
  styleHeader();
  styleDescription();
  styleGallery();
  styleMap();
  styleButtons();
}


export default function styleDirectory() {
  if ($('body').hasClass('directory')) {
    if ($('body').hasClass('other-map-body')) {
      styleSearchPage();
    } else {
      styleDirectoryPage();
    }
    handleSidebar();
    styleContent();
    initMap();
  } else if ($('body').hasClass('directory-merchand-page')) {
    styleMercPage();
  }
}
