/* jshint esversion: 6, multistr: true */
/*
  Styles the 4tyShops section
*/
import './tyshops.css';


function styleHeader() {
  const header = $('header');
  header.addClass('tyshops-header');
  header.find('.top-main-menu').appendTo(header.find('.header-actions-bar'));
  header.find('.top-main-menu-product-page .toggler').on('click', function(e) {
    e.preventDefault();
    header.find('.top-main-menu').toggleClass('open');
  });
  $('#productResults').appendTo(header);
  $('#productResults > ul > li > a').on('click', function(e) {
    e.preventDefault();
    $('#productResults > ul > li.active').removeClass('active');
    $(e.target).closest('li').toggleClass('active');
  });

  $('header h1 > a').attr('href', '/4tyshops');

  header.find('.top-main-menu-product-page').prepend('<a href="/" class="goHome">4ty.gr</a>');
}


function styleMain() {
  $('#right').addClass('container');
  $('#ty-shops-product-catalog li').each(function() {
    $(this).find('.product-price').insertAfter($(this).find('.product-category'));
  });
}


export default function style4tyShops() {
  if ($('body').hasClass('tyshops')) {
    styleHeader();
    styleMain();
  }
}
