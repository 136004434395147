/* jshint esversion: 6 */
import './googleAds.css';


function loadAd(callback) {
    $.getScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
    .done(function(script, textStatus) {
        callback();
        (adsbygoogle = window.adsbygoogle || []).push({});
        console.log('Custom add loaded.');
    })
    .fail(function( jqxhr, settings, exception ) {
        console.log('Error loading custom ad.');
    });
}


function createAdd(callback) {
    const element = document.createElement("script");
    element.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    document.body.appendChild(element);
    callback();
    console.log('Loaded add');
}


function loadHomeAds() {
    // home-proposals-ad
    createAdd(
        function() {
            $('.home #home-proposals').append('\
                <div class="ad-horizontal"> \
                    <ins class="adsbygoogle" \
                        style="display:block" \
                        data-ad-client="ca-pub-7549788657822029" \
                        data-ad-slot="7431897189" \
                        data-ad-format="auto" \
                        data-full-width-responsive="true"></ins> \
                </div> \
            ');
        }
    );

    // home-offer-ad
    // loadAd(
    //     function() {
    //         $('.home #home-offer').after('\
    //             <div class="ad-horizontal white"> \
    //                 <ins class="adsbygoogle" \
    //                     style="display:block" \
    //                     data-ad-client="ca-pub-7549788657822029" \
    //                     data-ad-slot="7869691511" \
    //                     data-ad-format="auto" \
    //                     data-full-width-responsive="true"></ins> \
    //             </div> \
    //         ');
    //     }
    // );

    // home-new-products-ad
    // loadAd(
    //     function() {
    //         $('.home .home-new-products').append('\
    //             <div class="ad-horizontal"> \
    //                 <ins class="adsbygoogle" \
    //                     style="display:block" \
    //                     data-ad-client="ca-pub-7549788657822029" \
    //                     data-ad-slot="4363714866" \
    //                     data-ad-format="auto" \
    //                     data-full-width-responsive="true"></ins> \
    //             </div> \
    //         ');
    //     }
    // );
}

function loadBusinessCategoriesAds() {
    // mapResults
    createAdd(
        function() {
            $('.business-categories #mapResults .col-3').append('\
                <div class="ad-square"> \
                    <ins class="adsbygoogle" \
                        style="display:block" \
                        data-ad-client="ca-pub-7549788657822029" \
                        data-ad-slot="3823469444" \
                        data-ad-format="auto" \
                        data-full-width-responsive="true"></ins> \
                </div> \
            ');
        }
    );
}


function loadDirectoryAds() {
    // Side
    createAdd(
        function() {
            let el = $('#animations-map-advertise');
            if (el.length <= 0) {
                el = $('#ads');
            }
            el.empty();
            el.append('\
                <div class="ad-square"> \
                    <ins class="adsbygoogle" \
                        style="display:block" \
                        data-ad-client="ca-pub-7549788657822029" \
                        data-ad-slot="9614852173" \
                        data-ad-format="auto" \
                        data-full-width-responsive="true"></ins> \
                </div> \
            ');
        }
    );
}


function loadProductCatalogueAds() {
    // 1
    if ($('#ty-shops-product-catalog li').eq(3).length > 0) {
        $('#ty-shops-product-catalog li').eq(3).after('\
            <div class="ad-horizontal"> \
                <ins class="adsbygoogle" \
                    style="display:block" \
                    data-ad-client="ca-pub-7549788657822029" \
                    data-ad-slot="3279532206" \
                    data-ad-format="auto" \
                    data-full-width-responsive="true"></ins> \
            </div> \
        ');
    }
    
    // 2
    // if ($('#ty-shops-product-catalog li').eq(11).length > 0) {
    //     $('#ty-shops-product-catalog li').eq(11).after('\
    //         <div class="ad-horizontal"> \
    //             <ins class="adsbygoogle" \
    //                 style="display:block" \
    //                 data-ad-client="ca-pub-7549788657822029" \
    //                 data-ad-slot="5277820216" \
    //                 data-ad-format="auto" \
    //                 data-full-width-responsive="true"></ins> \
    //         </div> \
    //     ');
    // }

    // 3
    // if ($('#ty-shops-product-catalog li').eq(27).length > 0) {
    //     $('#ty-shops-product-catalog li').eq(27).after('\
    //         <div class="ad-horizontal"> \
    //             <ins class="adsbygoogle" \
    //                 style="display:block" \
    //                 data-ad-client="ca-pub-7549788657822029" \
    //                 data-ad-slot="4242912473" \
    //                 data-ad-format="auto" \
    //                 data-full-width-responsive="true"></ins> \
    //         </div> \
    //     ');
    // }
}


function loadFooterAds() {
    createAdd(
        function() {
            $('footer').prepend('\
                <div class="ad-horizontal"> \
                    <ins class="adsbygoogle" \
                        style="display:block" \
                        data-ad-client="ca-pub-7549788657822029" \
                        data-ad-slot="2708670658" \
                        data-ad-format="auto" \
                        data-full-width-responsive="true"></ins> \
                </div> \
            ');
        }
    );
}


function loadProposalsAd() {
    createAdd(
        function() {
            $('#latestProposals').after('\
                <div class="ad-horizontal"> \
                    <ins class="adsbygoogle" \
                        style="display:block" \
                        data-ad-client="ca-pub-7549788657822029" \
                        data-ad-slot="2618839615" \
                        data-ad-format="auto" \
                        data-full-width-responsive="true"></ins> \
                </div> \
            ');
        }
    );
}


export default function initAds() {
    if ($('body').hasClass('home')) {
        loadHomeAds();
    } else if ($('body').hasClass('business-categories')) {
        loadBusinessCategoriesAds();
    } else if ($('body').hasClass('directory')) {
        loadDirectoryAds();
    } else if ($('body').hasClass('proposals')) {
        loadProposalsAd();
    } else if ($('#ty-shops-product-catalog').length > 0) {
        loadProductCatalogueAds();
    }

    if ($('body.merchant-one-page').length <= 0) {
        if ($('body.home').length <= 0) {
            loadFooterAds();
        }
    }
}
