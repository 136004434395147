/* jshint esversion: 6 */
/*
  Parses and returns contact details from meta tags.
*/


function getMetaContentsByProperty(mp){
  let metaTags=document.getElementsByTagName("meta");

  let metaContent = "";
  for (let i = 0; i < metaTags.length; i++) {
    if (metaTags[i].getAttribute("property") == mp) {
      metaContent = metaTags[i].getAttribute("content");
      break;
    }
  }
  return metaContent;
}


// Get the contact details from the meta tags
export default function getContactDetails() {
  let metaContactDetails = {
    'address': getMetaContentsByProperty('watergategr:contact:street_address'),
    'city': getMetaContentsByProperty('watergategr:contact:locality'),
    'province': getMetaContentsByProperty('watergategr:contact:region'),
    'postalCode': getMetaContentsByProperty('watergategr:contact:postal_code'),
    'county': getMetaContentsByProperty('watergategr:contact:country_name'),
    'email': getMetaContentsByProperty('watergategr:contact:email'),
    'phone': getMetaContentsByProperty('watergategr:contact:phone_number'),
    'fax': getMetaContentsByProperty('watergategr:contact:fax_number'),
    'longitude': getMetaContentsByProperty('place:location:longitude'),
    'latitude': getMetaContentsByProperty('place:location:latitude')
  };
  return metaContactDetails;
}
