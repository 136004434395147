/* jshint esversion: 6, multistr: true */

import './areas.css';


function styleHeader() {
    $('#hero-container').append('\
    <div class="container">\
        <div class="row">\
            <div class="title">\
                <h1>Περιοχές</h1>\
                <p>Δείτε εδώ όλες τις περιοχές καθώς και τις επιχειρήσεις που υπάρχουν σε αυτές ανά την Ελλάδα και τη Κύπρο.</p>\
            </div>\
        </div>\
    </div></div>\
    ');
}


function styleClosedAreas() {
    $('#areaindex').append('<div class="col-md-4 col-1"></div><div class="col-md-4 col-2"></div><div class="col-md-4 col-3"></div>');
    
    // Move all the areas to the main page (pinterest style)
    let colCount = 1;
    let areaCount = 1;
    $('#areaindex > ul > li').each(function() {
        $(this).attr('class', 'nomos').appendTo('#areaindex > .col-' + colCount);
        if ($(this).find('.poleis').length > 0) {
            $(this).addClass('has-poleis');
        }
        // $(this).addClass('closed');
        // Pass appending to col-3 for a few loops so that we make some space to place the banner there.
        if (colCount === 3) {
            colCount = 1;
        } else {
            colCount += 1;
        }
        areaCount += 1;
    });
}


function styleOpenAreas() {
    $('#customerareapres').append('<div class="col-md-3 col-nomos"></div><div class="col-md-4 col-1"></div><div class="col-md-4 col-2"></div>');
    $('.col-md-2.nomos').removeClass('col-md-2').appendTo('.col-nomos');
    $('ul.poleis').appendTo('.col-nomos > .nomos');
    $('#custareaback').appendTo('.col-nomos');

    let colCount = 1;
    let nomosCount = 1;
    $('#customerareapres .col-md-10 > .row.line').each(function() {
        $('#customerareapres .col-' + colCount).append('<li class="nomos has-poleis nomos-'+nomosCount+'"><a href="#">'+$(this).find('.border-column.category-business').html()+'</a></li>');

        $(this).find('.category-business > ul > li').addClass('poli');
        $(this).find('.category-business > ul').attr('class', 'poleis clearfix').appendTo($('li.nomos-'+nomosCount));

        nomosCount += 1;

        if (colCount === 1) {
            colCount = 2;
        } else {
            colCount = 1;
        }
    });
}


function handleEvents() {
    // Open - Close poleis
    $('.nomos.has-poleis').on('click', function(e) {
        if ($(e.target).closest('.poleis').length <= 0) {
            e.preventDefault();
            let $this = $(e.target);
            if ($this.hasClass('nomos') == false) {
                $this = $(e.target).closest('.nomos');
            }
            $this.toggleClass('closed');
            $this.children('.poleis').slideToggle('fast');
        }
    });
}


export default function styleAreas() {
    if ($('body').hasClass('areas')) {
        styleHeader();
        if ($('#customerareapres').length > 0) {
            styleOpenAreas();    
        } else {
            styleClosedAreas();
        }
        handleEvents();
    }

    
        
}