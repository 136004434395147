/* jshint esversion: 6, multistr: true */
/*
This script handles the maps in the entire homepage.
*/


import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './maps.css';
import mapMarkerIcon from '../img/mapMarker.png';
import mapMarkerShandowIcon from '../img/mapMarker_shandow.png';

/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
var bMap;


// Takes the optional parameter "el" which is the DOM element where the map is placed.
// Takes the optional parameter markerPosition which is an array of [x, y] values that dictate the center and marker on the map.
// If those otional parameters are not available then the map will show the entire Greece with no markers.
function initOSMap(el, markerPosition) {
    if (! el) {
        if ($('#mapHolder').length > 0) {
            $('#mapHolder').after('<div id="mapContainer"></div>');
        }
        el = $('#mapContainer');
    }
    
    if (el.length > 0) {
        let defaultCenter = [38.3, 23.5803244]; // Greece
        let defaultZoom = 6;
        let mapMarker;

        if (markerPosition) {
            // Set the center, zoom and marker position if markerPosition is available.
            defaultCenter = markerPosition;
            defaultZoom = 14;
            mapMarker = L.icon({
                iconUrl:  "https://www.4ty.gr" + "/custom-code/" + mapMarkerIcon,
                shadowUrl: "https://www.4ty.gr" + "/custom-code/" + mapMarkerShandowIcon,
            
                iconSize:     [40, 40], // size of the icon
                shadowSize:   [80, 40], // size of the shadow
                iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
                shadowAnchor: [40, 40],  // the same for the shadow
                popupAnchor:  [-20, -40] // point from which the popup should open relative to the iconAnchor
            });
        }
        bMap = L.map(el.attr('id'));
        // Tiles provider: https://www.esri.com/en-us/home
        const basemap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
        });

        bMap.setView(defaultCenter, defaultZoom);

        basemap.addTo(bMap);

        // Add a marker if available.
        if (mapMarker) {
            L.marker(markerPosition, {icon: mapMarker}).addTo(bMap);
        }
    }
}


// We call it in businesses category click event. It creates a dictionary of records in this form:
// {recordId: {
//     category: {id, name, icon},
//     merchants: []
// }}
// When and if it gets any records, it calls the placeMarkers function.
// This peace of obfuscated marvel fetches merchant records by category... Then it filters, then refilters the data because this is a JSON request 
// that fetches a document!!!!!
// Oh yeah. A document. Because if it were to get a json responce that would be really weird. ....FUCK!
function fetchRecords(id, searchAllCategories) {
    let searchUrl = '/specialMarkers.php';
    if (searchAllCategories === true) {
        searchUrl = '/ajax-category-markers.php'
    }

	$.ajax({
		data: {
			hl: lang,
			id: id
		},
		success: function( data, status, reqObj ) {

            let id = $(data).find('request').find('id').text();
            let records = {};
			records[id] = new Object();
			records[id].category = new Object();
			records[id].category.id = $(data).find('category').find('id').text();
			records[id].category.name = $(data).find('category').find('name').text();
            records[id].category.icon = $(data).find('category').find('icon').text();
            
			records[id].merchants = new Array();
			$(data).find('record').each( function() {
				let domain = $(this).find('domain').text();
				let mdomain = $(this).find('mdomain').text();
				let i = records[id].merchants.length;
				records[id].merchants[i] = new Object();
				records[id].merchants[i].x = parseFloat( $(this).find('x').text() );
				records[id].merchants[i].y = parseFloat( $(this).find('y').text() );
				records[id].merchants[i].id = parseInt( $(this).find('id').text() );
				let info = '<div class="content">';
				let image = $(this).find('image').text();
				if( image.length > 0 ) info += '<img src="' + image + '" width="120" height="90" align="left" />';
				let cardType = $(this).find('type').text();
				let subdomain = $(this).find('subdomain').text();
				let url = $(this).find('homeURL').text();
				let imageURL = $(this).find('imageURL').text();
				let videoURL = $(this).find('videoURL').text();
				info += '<a href="' + url + '" target="_blank" class="gamaplink">' + records[id].category.name + ', ' + $(this).find('title').text() + '</a><br />' + $(this).find('area').text() + ', ' + $(this).find('address').text();
				if( $(this).find('hasImages').text() == 'true' ) {
					if( $(this).find('hasVideo').text() == 'true' ) {
						info += '<div style="text-align:right"><a href="' + imageURL + '" target="_blank"><img src="/images/tpl2015/map/photo.png" /></a><a href="' + videoURL + '" target="_blank"><img src="/images/tpl2015/map/video.png" /></a></div>';
					} else {
						info += '<div style="text-align:right"><a href="' + imageURL + '" target="_blank"><img src="/images/tpl2015/map/photo.png" /></a></div>';
					}
				} else {
					if( $(this).find('hasVideo').text() == true ) {
						info += '<div style="text-align:right"><a href="' + videoURL + '" target="_blank"><img src="/images/tpl2015/map/video.png" /></a></div>';
					}
				}
				info += '</div>';
				records[id].merchants[i].info = info;
            });

            placeMarkers(records);
		},
		url: searchUrl
	})
}


var markersList = {};
function placeMarkers(records) {
    // Iterates over the records created by the abomination above and places them into the map.
    // Creates a dictionary of this form:
    // {categoryId: {
    //     merchantId: markerObject
    // }}
    let merchant;
    let markerIcon = L.Icon.extend({
        options: {
            iconSize:     [30, 30],
            iconAnchor:   [15, 30],
            popupAnchor:  [0, -30]
        }
    });

    for (let key in records) {
        if (records.hasOwnProperty(key)) {
            let currentRecord = records[key];
            let catIcon = new markerIcon({iconUrl: currentRecord.category.icon});
            markersList[key] = {};

            for (let i=0; i < currentRecord.merchants.length; i++) {
                merchant = currentRecord.merchants[i];
                markersList[key][merchant.id] = L.marker(
                    [merchant.x, merchant.y], 
                    {icon: catIcon}
                ).addTo(bMap).bindPopup(merchant.info);
            }

        }
    }
}



function removeMarkers(catId) {
    // This function is called if the category we clicked gets unchecked. Which means that we need to remove the markers.
    // It iterates over the markersList object of this particullar category id. It removes the markers from the map and
    // the dictionary.
    for (let key in markersList[catId]) {
        let marker = markersList[catId][key];
        marker.removeFrom(bMap);
        delete markersList[catId][key];
    }
}


// Places the markers of all the merchants that we got from search results.
function placeSearchMarkers() {
    if ( typeof merchants != 'undefined' ) {
        let markerIcon = L.Icon.extend({
            options: {
                iconSize:     [30, 30],
                iconAnchor:   [15, 30],
                popupAnchor:  [0, -30]
            }
        });
        let mercIcon = new markerIcon({iconUrl: "https://"+document.domain+"/images/tpl2015/map/map1.png"});
        for (let i = 0; i < merchants.length; i++) {
            let merchant = merchants[i];

            markersList[merchant.id] = L.marker(
                [merchant.pointX, merchant.pointY], 
                {icon: mercIcon}
            ).addTo(bMap).bindPopup(merchant.info);
        }
    }
}


// Handles the creation of the map and the markers' placement.
// Takes the optional parameter "el" which is the DOM element where the map is placed.
// Takes the optional parameter markerPosition which is an array of [x, y] values that dictate the center and marker on the map.
// Look at initOSMap function for more info about the map creation.
export default function initMap(el, markerPosition) {
    $(document).ready(function() {
        console.log('==> Initializing OpenStreetMap.');
        initOSMap(el, markerPosition);

        if ($('body').hasClass('business-categories')) {
            // Business categories map

            // Businesses
            $('#mapResults .category > ul > li').on('click', function() {
                let catId = $(this).data('id');
                let catEl = $(this);
                setTimeout(function() {
                    if (catId) {
                        if (catEl.hasClass('map')) {
                            fetchRecords(catId, true);
                        } else {
                            removeMarkers(catId);
                        }
                    }
                }, 200);
            });

            // Rest of businesses categories.
            $('#mapResults .map-result.category').on('click', function() {
                if ($(this).find('input[type="checkbox"]').length > 0) {
                    let catId = $(this).find('input[type="checkbox"]').attr('rel');
                    if (catId) {
                        if ($(this).hasClass('map')) {
                            fetchRecords(catId, false);
                        } else {
                            removeMarkers(catId);
                        }
                    }
                }
            });
        } else if ($('body').hasClass('other-map-body map directory')) {
            // Search map. When the search page loads, the search results are stored in the "merchants" variable.
            if ( typeof merchants != 'undefined' ) {
                placeSearchMarkers();
            } else {
                console.log('==> There are no merchants to show.');
            }
        }   
    })
}