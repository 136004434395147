/* jshint esversion: 6 */
import './footer.css';

import headsetWhite from '../img/svg/headset_white.svg';


export default function styleFooter() {
  $('footer > .row').each(function() {
    $(this).children().wrapAll('<div class="container"></div>');
  });
  // Remove Google plus
  $('footer .social-footer .footer-google').parent('li').remove();
  // instagram
  $('footer .social-footer > ul').append('<li><a class="footer-instagram" href="https://www.instagram.com/epaggelmatikosodigos_4ty.gr/" title itemporp="url"><span>instagram</span></li>');
  // facebook
  $('footer .footer-facebook').attr('href', 'https://www.facebook.com/4tygr');
  // logo
  $('footer .row:first-of-type .col-md-4:first-of-type > img').wrap('<a href="/"></a>');
}
