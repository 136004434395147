/* jshint esversion: 6, multistr: true */
/*
  Styles the header.
*/
import './styles.css';


function createLangsDropdown(header) {
  let headerLang = header.find('.header-language');
  if (headerLang.length <= 0) {
    headerLang = header.find('.eshop-language').addClass('header-language');
  }
  headerLang.addClass('btn-group');
  headerLang.children('a').wrap('<li></li>');
  headerLang.children('li').wrapAll('<ul class="dropdown-menu"></ul>');
  headerLang.prepend('\
    <button type="button" class="dropdown-toggle header-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\
      '+headerLang.find('a[data-selected=selected]').text()+' <span class="caret"></span>\
    </button>\
  ');
}


function createHeaderActionsBar() {
  const header = $('body.c2018 header');
  // header-actions-bar
  header.children('.row').first().addClass('header-actions-bar').children().wrapAll('<div class="container"></div>');
  header.find('#loginaction').addClass('header-button');
  createLangsDropdown(header);
}


function appendAnniversaryMsg() {
  // Appends a block after the slideshow that leads to the 10 years anniversary text.
  $('main > .container').prepend('\
  <div id="anniversary-block" class="row">\
    <div class="container">\
      <div class="row">\
        <div class="col-md-2 image"></div> \
        <div class="col-md-10"> \
          <h3>Προσθέστε την επιχείρησή σας</h3>\
          <p>σε έναν από τους μεγαλύτερους ηλεκτρονικούς επαγγελματικούς οδηγούς \
          και εξασφαλίστε νέους πελάτες.</p>\
          <a href="https://www.4ty.gr/contact/el" class="btn">Εγγραφή</a>\
        </div>\
      </div>\
    </div>\
  </div>')
}


function styleHomeSlideshow() {
  if ($('.home-slideshow').length > 0) {
    console.log('==> Styling slideshow');
    let height = window.innerHeight;
    if ($('#anniversary-block').length > 0) {
      height -= $('#anniversary-block').outerHeight();
    }

    $('.home-slideshow').height(height);
  }
}


function styleHomeHeader() {
  const header = $('body.c2018 header');
  header.addClass('home-header');
  // slideshow header
  $('.home-slideshow').appendTo(header);
  header.children('.row:not(.header-actions-bar)').wrapAll('<div class="container slideshow-header"></div>');
  $('.slideshow-header').appendTo('.home-slideshow');
  appendAnniversaryMsg();
  styleHomeSlideshow();
}


function styleTyShopsHeader() {
  const header = $('body.c2018 header');
  header.addClass('tyshops-header');
  // Create the header's collapse menu
  header.find('.top-main-menu-product-page').prepend('<a href="#" class="toggler">Menu</a>');
  header.find('.top-main-menu-product-page > ul').insertAfter(header.find('.header-actions-bar')).addClass('container').wrap('<div class="row top-main-menu"></div>');
}


function styleHeaderInputs() {
  $('header .header-search input#category').wrap('<div class="category-input-wrapper"></div>');
  $('header .header-search input#area, header .search-button-header').wrapAll('<div class="area-input-wrapper"></div>');
}


// Creates a compact header.
function createCompactHeader() {
  const headerEl = $('header > .row.header-actions-bar');
  headerEl.addClass('compact-header');
  headerEl.find('.container > .col-md-6:first-of-type').addClass('collapse-menu').append('<a id="header-logo" href="/"></a>');
  headerEl.find('.container > .col-md-6:last-of-type').addClass('account-lang');

  if ($('header').hasClass('home-header') && headerEl.find('.header-search').length <= 0) {
    $('header .header-search').parent().addClass('header-search-container');
  }
  $('header .header-search').appendTo(headerEl.find('.collapse-menu'));
}


function revertCompactHeader() {
  const headerEl = $('header > .row.header-actions-bar');
  headerEl.removeClass('compact-header');
  headerEl.find('#header-logo').remove();
  headerEl.find('.container > .col-md-6:last-of-type').removeClass('account-lang');
  headerEl.find('.header-search').appendTo('header .header-search-container');
}


// Listens for scroll event on window and contstructs a sets sticky class to
// the header when needed. If we are in home page it also contstructs the
// compact header.
function hundleCompactSticky() {
  const headerEl = $('header');
  $(window).scroll(function() {
    if ( $(window).scrollTop() > 400 ) {
      if (! headerEl.hasClass('compact-sticky')) {
        $('header').addClass('compact-sticky');
        if (headerEl.hasClass('home-header')) {
          createCompactHeader();
        }
        $('#go-top').show();
      }
    } else {
      $('header').removeClass('compact-sticky');
      if (headerEl.hasClass('home-header')) {
        revertCompactHeader();
      }
      $('#go-top').hide();
    }
  });
}


function styleAndShowLoginForm() {
  const loginForm = $('#loginform');
  let failedLogin = loginForm.find('.failed-login').length > 0;
  let passwordChange = loginForm.find('#loginPhone').length > 0 || loginForm.find('.forgot-password').length > 0;
  
  $('#loginaction').wrap('<div class="header-login"></div>');
  
  if (failedLogin === false && passwordChange === false) {
    loginForm.addClass('closed');
    loginForm.hide();
  }
  
  loginForm.find('.container').prepend(' \
    <div class="title"><span>Σύνδεση</span><span class="close">X</span></div> \
    <div class="row"> \
      <div id="login-text" class="col-md-5"> \
        <img src="'+$('footer img').first().attr('src')+'" class="logo" /> \
        <p>Ηλεκρονικός Επαγγελματικός Οδηγός Ελλάδας</p> \
        <div class="technical-support"> \
          <div class="col-md-4"> \
            <div class="icon-support"></div>\
          </div> \
          <div class="col-md-8 text-support"> \
          </div> \
        </div> \
      </div> \
    </div> \
  ');

  if (failedLogin === true) {
    loginForm.find('.title > span').first().text('Αποτυχία σύνδεσης');
    loginForm.addClass('failed');
  } else if (passwordChange === true) {
    loginForm.find('.title > span').first().text('Υπενθύμιση κωδικού');
    loginForm.addClass('password-change');
    loginForm.find('button[type=submit]').before('<a href="/" class="goto-forgot-password">Επιστροφή</a>');
    if (loginForm.find('.forgot-password').length > 0) {
      loginForm.addClass('password-change-message');
    }
  } else {
    loginForm.find('button[type=submit]').before('<a href="/?forgotPassword=1" class="goto-forgot-password">Ξεχάσατε τον κωδικό σας;</a>');
  }

  loginForm.find('form').addClass('col-md-7').appendTo('#loginform > .container > .row');
  loginForm.find('input').each(function() {
    $(this).attr('placeholder', $(this).prev('label').text());
  });

  setTimeout(function() {
    let tecSupport = $('footer > .row > .container .col-md-4 > .row > .col-md-8').first();
    tecSupport.find('.details-title').clone().appendTo('#login-text .text-support');
    tecSupport.find('.details-phone').clone().appendTo('#login-text .text-support');
  }, 1000);

  // Open login window
  $('#loginaction').on('click', function(e) {
    e.preventDefault();
    $('#loginform').css('display', 'flex');
    $('#loginform').removeClass('closed');
  });

  // Close login window
  $('#loginform .title .close').on('click', function() {
    $('#loginform').addClass('closed');
    $('#loginform').fadeOut('fast');
  });
  $('#loginform').on('click', function(e) {
    if ($(e.target).parent('body').length > 0) {
      $('#loginform').addClass('closed');
      $('#loginform').fadeOut('fast');
    }
  });
}


function mobileHeader() {
  const parentEl = $('header');
  console.log('==> Creating mobile header');
  parentEl.find('.container > .col-md-6:first-of-type').addClass('collapse-menu');
  parentEl.find('.top-main-menu').appendTo(parentEl.find('.collapse-menu'));
  $('header > .row.header-actions-bar').append('<div class="toggler"></div>');

  parentEl.off().on('click', '.toggler', function() {
    parentEl.find('.collapse-menu').slideToggle('fast');
  });
}


function desktopHeader() {
  const parentEl = $('header');
  console.log('==> Creating desktop header');
  parentEl.find('.collapse-menu').css('display', 'flex');
  parentEl.find('.top-main-menu').insertBefore('#hero-container');
  parentEl.find('.toggler').remove();
  if ($('.home-header').length > 0) {
    $('.top-main-menu').appendTo('.slideshow-header');
  }
}


function initAutocomplete() {
  $("#category").autocomplete({
    source: "category-hints.php?hl=" + lang,
    open: function() {
      $(this).autocomplete('widget').css('z-index', 9999);
      $(this).autocomplete('widget').css('width', $("#category").width()+'px');
      return false;
    }
  });

  $("#area").autocomplete({
    source: "area-hints.php?hl=" + lang,
    open: function() {
      $(this).autocomplete('widget').css('z-index', 9999);
      $(this).autocomplete('widget').css('width', $("#area").width()+'px');
      return false;
    }
  });
}


// Header search ===================================================================================
function handleSearchForm() {
  let searchForm = $('form.header-search');
  if (searchForm.length > 0) {
    searchForm.find('#category').attr('required');
    searchForm.removeAttr('onsubmit').on('submit', function(e) {
      let categoryVal = seachForm.find('#category').val().trim();
      let areaVal = searchForm.find('#area').val().trim();
      if(areaVal == "") {
          var newURL = '/companies/' + lang + '/' + categoryVal + '/';
        } else {
          var newURL = '/companies/' + lang + '/' + categoryVal + '/' + areaValue + '/';
        }
        window.location = newURL;
    });
  }
}


export default function styleHeader() {
  styleHeaderInputs();
  createHeaderActionsBar();
  handleSearchForm();

  if ($('body').hasClass('home')) {
    styleHomeHeader();
  } else if ($('body').hasClass('tyshops')) {
    styleTyShopsHeader();
  } else {
    createCompactHeader();
    $('header .top-main-menu > ul').addClass('container');
    $('header .top-main-menu').after('<div id="hero-container" class="row"></div>');
  }
  hundleCompactSticky();
  styleAndShowLoginForm();

  if ($(window).width() < 768) {
    mobileHeader();
  }

  if ($('body').hasClass('proposals') || $('body').hasClass('directory') || $('body').hasClass('offers') || $('body').hasClass('areas')) {
    $('#hero-container').addClass('split-city-header');
  }

  $(document).ready(function() {
    window.onresize = function() {
      if (window.outerWidth < 768) {
        mobileHeader();
      } else {
        desktopHeader();
      }
      if ($('body').hasClass('home')) { styleHomeSlideshow(); }
    }
  });
}
